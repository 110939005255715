import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  HttpError,
  useUpdate,
} from "@pankod/refine-core";

import {
  Typography,
  Row,
  Col,
  Card,
  Icons,
  DateField,
  Table,
  List,
  useTable,
  TextField,
  Tag,
  useDrawerForm,
  EditButton,
  Menu,
  Dropdown,
  Avatar,
  Progress,
  Divider,
} from "@pankod/refine-antd";

import { IAgent, IFile, IAlarm } from "interfaces";
import { EditAlarm } from "components/alarms";
const it = require("dayjs/locale/it")
const { Text, Title } = Typography;

export const AgentShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow<IAgent>();
  const { mutate } = useUpdate<IAlarm>();

  const { data } = queryResult;
  const agent = data?.data;

  const { tableProps } = useTable<IFile, HttpError>({
    resource: "files",
    permanentFilter: [
      {
        field: "agentId",
        operator: "eq",
        value: agent?.id,
      },
    ],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ]
  });

  const propsAlarms = useTable<
    IAlarm,
    HttpError
  >({
    resource: "alarms",
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "agentId",
        operator: "eq",
        value: agent?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: agent !== undefined,
    },
    syncWithLocation: false,
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<IAlarm>({
    action: "edit",
    resource: "alarms",
    redirect: false,
  });

  const onAlarmStatusUpdate = (alarmId: string, status: string) => {
    mutate({
      resource: "alarms",
      id: alarmId,
      values: { status },
    });
  }

  const moreMenu = (id: string) => (
    <Menu mode="vertical">
      <Menu.Item
        key="accept"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.CheckCircleOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => onAlarmStatusUpdate(id, "CONFIRMED")}
      >
        {t("alarms.fields.status.confirmed")}
      </Menu.Item>
      <Menu.Item
        key="reject"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.CloseCircleOutlined
            style={{
              color: "#EE2A1E",
              fontSize: 17,
            }}
          />
        }
        onClick={() => onAlarmStatusUpdate(id, "REJECTED")}
      >
        {t("alarms.fields.status.rejected")}
      </Menu.Item>
    </Menu>
  );

  const getImageByFileType = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
        return "002-pdf-file.png"
      case "text/plain":
        return "001-txt-file.png"
      case "text/csv":
        return "004-csv.png"
      case "application/vnd.ms-powerpoint":
        return "005-ppt-file.png"
      case "image/png":
        return "007-png-file.png"
      case "application/zip":
        return "008-zip-file.png"
      case "application/vnd.rar":
        return "009-rar-file.png"
      case "application/xml":
      case "text/xml":
      case "application/atom+xml":
        return "006-xml-file.png"
    }
  }

  const getImageByReason = (reason: string) => {
    switch (reason) {
      case "NETWORK:LIMIT-EXCEEDING":
        return "https://cdn-icons-png.flaticon.com/512/4330/4330810.png"
      default:
        return "https://cdn-icons-png.flaticon.com/512/3442/3442207.png"
    }
  }

  const calcFormat = (percent: number | undefined) => `${agent?.backupFilesSize} MB`

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} xs={24}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Row>
              <Col xs={24}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  textAlign: 'center'
                }}>
                  <Title level={5}>
                    {agent?.displayName}
                  </Title>
                  <Tag color={agent?.status === "ACTIVE" ? "green" : (agent?.status === "DISABLED" ? "red" : "gold")}>{t("agents.fields.status." + agent?.status.toLowerCase())}</Tag>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Progress width={230} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }} type="dashboard" percent={(((agent?.backupFilesSize || 1) / (agent?.backupStorageLimit || 1)) * 100) || 0} format={(percent) => calcFormat(percent)} />
                <TextField style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px" }}
                  value={t("agents.fields.diskSpace")}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={12}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <Text style={{ fontSize: "18px" }}>{t("agents.fields.savedFiles")}</Text>
                  <Text strong style={{ fontSize: "23px" }}>{agent?.backupFilesCounter}</Text>
                </div>
              </Col>
              <Col xs={12}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <Text style={{ fontSize: "18px" }}>{t("agents.fields.pending_alarms")}</Text>
                  <Text strong style={{ fontSize: "23px" }}>{agent?.alarmsPendingCounter}/{agent?.alarmsSentCounter}</Text>
                </div>
              </Col>
            </Row>
            <Divider />
            {agent?.backupEnabled ? (
              <Tag color="blue">BACKUP</Tag>
            ) : (null)}
            {agent?.networkUploadCheckEnabled ? (
              <Tag color="cyan">NETWORK MONITORING</Tag>
            ) : (null)}
            {agent?.restoreEnabled ? (
              <Tag color="volcano">FILE MONITORING</Tag>
            ) : (null)}
          </Card>
        </Col>
        <Col xl={16} xs={24}>
          <List
            title={t("alarms.titles.list")}
            pageHeaderProps={{
              extra: <></>,
            }}
            breadcrumb={null}
          >
            <Table {...propsAlarms.tableProps} rowKey="id">
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("alarms.fields.alarm")}
                render={(value: string, _: IAlarm) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={getImageByReason(_?.reason)}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 12, fontWeight: 600 }}>
                        #{_?.id}
                      </Text>
                      <Tag style={{ width: "45%", display: "flex", justifyContent: "center" }} color={_?.status === "CONFIRMED" ? "green" : (_?.status === "REJECTED" ? "red" : "gold")}>{t("alarms.fields.status." + _?.status.toLowerCase())}</Tag>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                title={t("alarms.fields.createdAt")}
                render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={it} format="LLL" />}
                sorter
              />
              <Table.Column<IAlarm>
                title={t("table.actions")}
                key="actions"
                render={(record) => (
                  <Row gutter={8}>
                    {record.status === "PENDING" ? <Col span={12}>
                      <Dropdown
                        overlay={moreMenu(record.id)}
                        trigger={["click"]}
                      >
                        <Icons.MoreOutlined
                          style={{
                            fontSize: 24,
                          }}
                        />
                      </Dropdown>
                    </Col> : null}
                    <Col span={12}>
                      <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                        onClick={() => editShow(record.id)}
                      />
                    </Col>
                  </Row>
                )}
                fixed="right"
              />
            </Table>
          </List>
          <List
            title={t("agents.fields.files")}
            pageHeaderProps={{
              extra: <></>,
              style: {
                marginTop: "1em",
              },
            }}
            breadcrumb={null}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("agents.fields.files")}
                render={(value: string, _: IFile) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={"/images/" + getImageByFileType(_?.type)}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.id}
                      </Text>
                      <Text style={{ fontSize: 15 }}>{_?.path}</Text>
                      <Text style={{ fontSize: 12 }}>{_?.size} MB</Text>
                      <Tag style={{maxWidth: "100px", textAlign: "center"}} color={'blue'}>{_.status}</Tag>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="backupDate"
                dataIndex="backupDate"
                title={t("files.backupDate")}
                render={(value) => <DateField value={value} locales={it} format="LLL" />}
                sorter
              />
            </Table>
          </List>
        </Col>
      </Row>
      <EditAlarm
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
