import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useUpdate,
  useList
} from "@pankod/refine-core";

import {
  List,
  Table,
  Avatar,
  useTable,
  DateField,
  BooleanField,
  Card,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Select,
  FormProps,
  Row,
  Col,
  useDrawerForm,
  CreateButton,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
  Tag,
  TextField,
  Menu,
  Dropdown,
  Typography,
  Tooltip
} from "@pankod/refine-antd";

import { IAgent, IFile } from "interfaces";
import { EditAlarm } from "components/alarms";
import { Dayjs } from "dayjs";
import { AgentList } from "pages/agents";
const it = require("dayjs/locale/it")

const { Text, Title } = Typography;

export const FilesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IFile,
    HttpError,
    { q: string, agentId: string, backupDate: [Dayjs, Dayjs] }
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { agentId, backupDate } = params;


      filters.push(
        {
          field: "backupDate",
          operator: "gte",
          value: backupDate
            ? backupDate[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "backupDate",
          operator: "lte",
          value: backupDate
            ? backupDate[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "agentId",
        operator: "eq",
        value: agentId !== "" ? agentId : undefined,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const { data: agents } = useList<IAgent>({
    resource: "agents",
    config: {
      sort: [{ order: "desc", field: "createdAt" }],
    },
  });

  if (tableProps?.dataSource && agents) {
    tableProps.dataSource = tableProps?.dataSource?.map((d: IFile) => {
      d.agent = agents?.data?.filter(e => e.id === d?.agentId)[0] || null
      return d
    })
  }

  const t = useTranslate();
  const { RangePicker } = DatePicker;

  const getImageByFileType = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
        return "002-pdf-file.png"
      case "text/plain":
        return "001-txt-file.png"
      case "text/csv":
        return "004-csv.png"
      case "application/vnd.ms-powerpoint":
        return "005-ppt-file.png"
      case "image/png":
        return "007-png-file.png"
      case "application/zip":
        return "008-zip-file.png"
      case "application/vnd.rar":
        return "009-rar-file.png"
      case "application/xml":
      case "text/xml":
      case "application/atom+xml":
        return "006-xml-file.png"
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title={t("files.filter.title")}>
            <Form layout="vertical" {...searchFormProps}>
              <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={12}>
                  <Form.Item label={t("files.filter.createdAt.label")} name="backupDate">
                    <RangePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={12}>
                  <Form.Item label={t("agentactivies.filter.agentId.label")} name="agentId">
                    <Select
                      allowClear
                      style={{ minWidth: 150 }}
                      placeholder={t("agentactivies.filter.agentId.placeholder")}

                    >
                      {agents?.data?.map((a: IAgent) => (
                        <option key={a.id} value={a.id}>
                          <div>{a.displayName}</div>
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                  <Form.Item>
                    <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                      {t("files.filter.submit")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            pageHeaderProps={{
              extra: (
                <></>
              ),
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("agents.fields.files")}
                render={(value: string, _: IFile) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={"/images/" + getImageByFileType(_?.type)}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.id}
                      </Text>
                      <Text style={{ fontSize: 15 }}><Tooltip placement="topLeft" title={_?.path}>{_?.path ? (_?.path.length > 20 ? "..." + _?.path.slice(-20) : _?.path) : ""}</Tooltip></Text>
                      <Text style={{ fontSize: 12 }}>{_?.size} MB</Text>
                      <Tag style={{maxWidth: "100px", textAlign: "center"}} color={'blue'}>{_.status}</Tag>

                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("agents.fields.agent")}
                render={(value: string, _: IFile) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.agent?.type === "WINDOWS" ? "/images/windows.png" : (_.agent?.type === "MAC" ? "/images/ios.png" : "/images/linux.png")}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.agentId}
                      </Text>
                      <Text style={{ fontSize: 15 }}>{_?.agent?.displayName}</Text>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="backupDate"
                dataIndex="backupDate"
                title={t("files.backupDate")}
                render={(value) => <DateField value={value} locales={it} format="LLL" />}
                sorter
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};