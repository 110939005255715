import { Icons } from "@pankod/refine-antd";
import { RefineKbar } from "@pankod/refine-kbar";

import "./style.less";

const { LeftOutlined } = Icons;

export const OffLayoutArea = () => {
    return (
        <div className="toggle-container">
            <RefineKbar />
            <LeftOutlined />
        </div>
    );
};
