import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  DateField,
  Card,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Select,
  FormProps,
  Row,
  Col,
  Tag,
  TextField,
  Avatar,
  Typography
} from "@pankod/refine-antd";

import { IAgent, IAgentLog, IAgentLogFilterVariables } from "interfaces";

const { Text } = Typography;

export const AgentLogList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IAgentLog,
    HttpError,
    IAgentLogFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { /* q,  */createdAt, agentId } = params;

      /*       filters.push({
              field: "q",
              operator: "eq",
              value: q,
            }); */

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "agentId",
        operator: "eq",
        value: agentId !== "" ? agentId : undefined,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const t = useTranslate();

  const operationStatusColor = (
    operation: string,
  ): { color: string; text: string } | undefined => {
    switch (operation) {
      case "CREATED":
        return { color: "orange", text: "created" };
      case "LIST":
        return { color: "cyan", text: "list" };
      case "SHOW":
        return { color: "green", text: "show" };
      case "UPDATE":
        return { color: "pink", text: "update" };
      case "DELETE":
        return { color: "red", text: "delete" };
      case "RESET_INSTALLATION":
        return { color: "geekblue", text: "login" };
      default:
        return { color: "green", text: "" };
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xl={6} lg={24} xs={24}>
        <Card title={t("agentactivies.titles.list")}>
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>
      <Col xl={18} xs={24}>
        <List
          pageHeaderProps={{
            extra: (
              <></>
            ),
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title={t("agentactivies.fields.createdAt")}
              render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
              sorter
            />
            <Table.Column
              key="userId"
              dataIndex="userId"
              title={t("agentactivies.fields.operator")}
              render={(value: string, _: IAgentLog) => (

                <div className="product">
                  <Avatar
                    size={{
                      md: 40,
                      lg: 64,
                      xl: 64,
                      xxl: 64,
                    }}
                    src={_?.agentId === "WINDOWS" ? "/images/windows.png" : (_.agentId === "MAC" ? "/images/ios.png" : "/images/linux.png")}
                  />
                  <div className="product-text">
                    <Text style={{ fontSize: 18, fontWeight: 800 }}>
                      {_?.agentDisplayName}
                    </Text>
                    <Text style={{ fontSize: 10 }}>#{_?.agentId}</Text>
                  </div>
                </div>
              )}
            />
            <Table.Column
              key="operation"
              dataIndex="operation"
              title={t("agentactivies.fields.activity")}
              render={(value: string, _: IAgentLog) => (
                <>
                  <Row>
                    <Tag color={operationStatusColor(value)?.color}>{value + " " + _.onModelType /* + " " + _.onModelRef */}</Tag>
                  </Row>
                  {_?.onModelRef ? (<Row>
                    <Tag color={"#C3C5C5"}>{_.onModelRef}</Tag>
                  </Row>) : (null)}
                </>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const { RangePicker } = DatePicker;

  const { tableProps } = useTable<IAgent, HttpError>({
    resource: "agents",
    /*     permanentFilter: [
          {
            field: "__agentId",
            operator: "eq",
            value: agent?.id,
          },
        ], */
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ]
  });

  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        {/*         <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("agentactivies.filter.search.label")} name="q">
            <Input
              placeholder={t("agentactivies.filter.search.placeholder")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("agentactivies.filter.createdAt.label")} name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("agentactivies.filter.agentId.label")} name="agentId">
            <Select
              allowClear
              style={{ minWidth: 150 }}
              placeholder={t("agentactivies.filter.agentId.placeholder")}
              options={tableProps?.dataSource?.map(d => {
                return {
                  label: d.displayName,
                  value: d.id
                }
              })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("agents.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
