import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useNavigation,
  useDelete,
  useGetIdentity,
} from "@pankod/refine-core";

import {
  List,
  Table,
  Avatar,
  useTable,
  DateField,
  BooleanField,
  Card,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Select,
  FormProps,
  Row,
  Col,
  Dropdown,
  Menu,
  useDrawerForm,
  CreateButton,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
  Typography,
  Tag,
} from "@pankod/refine-antd";

import { IAgent, IUser, IAgentFilterVariables } from "interfaces";
import "./style.less";

const { Text } = Typography;
import { CreateAgent, EditAgent } from "components/agents";

export const AgentList: React.FC<IResourceComponentsProps> = () => {
  const userLogged = useGetIdentity<IUser>()
  const { tableProps, searchFormProps } = useTable<
    IAgent,
    HttpError,
    IAgentFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "_id__in",
        operator: "in",
        value: userLogged?.data?.monitoredAgentIds || [],
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { id, status, createdAt, type } = params;

      filters.push({
        field: "_id",
        operator: "eq",
        value: id,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "type",
        operator: "eq",
        value: type,
      });

      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<IAgent>({
    action: "edit",
    resource: "agents",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<IAgent>({
    action: "create",
    resource: "agents",
    redirect: false,
  });

  const t = useTranslate();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title={t("agents.filter.title")}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            pageHeaderProps={{
              extra: (
                <CreateButton
                  onClick={() => {
                    createShow();
                  }}
                >
                  {t("agents.buttons.addAgent")}
                </CreateButton>
              ),
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("agents.fields.agent")}
                render={(value: string, _: IAgent) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.type === "WINDOWS" ? "/images/windows.png" : (_?.type === "MAC" ? "/images/ios.png" : "/images/linux.png")}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.id}
                      </Text>
                      <Text style={{ fontSize: 15 }}>{_?.displayName}</Text>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="status"
                dataIndex="status"
                title={t("agents.fields.status.label")}
                render={(_, record: IAgent) => {
                  return (
                    <Tag color={record?.status === "ACTIVE" ? "green" : (record.status === "DISABLED" ? "red" : "gold")}>{t("agents.fields.status." + record?.status.toLowerCase())}</Tag>
                  )
                }}
              />
              <Table.Column
                key="status"
                dataIndex="status"
                title={t("agents.fields.configuration")}
                render={(_, record: IAgent) => {
                  return (
                    <>
                      {record?.backupEnabled ? (
                        <Tag color="blue">BACKUP</Tag>
                      ) : (null)}
                      {record?.networkUploadCheckEnabled ? (
                        <Tag color="blue">NETWORK MONITORING</Tag>
                      ) : (null)}
                      {record?.restoreEnabled ? (
                        <Tag color="blue">FILE MONITORING</Tag>
                      ) : (null)}
                    </>
                  )
                }}
              />
              <Table.Column<IAgent>
                title={t("table.actions")}
                dataIndex="actions"
                fixed="right"
                render={(_, record) => (
                  <Space>
                    {userLogged?.data?.role === 'ADMIN' ? (
                      <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                        onClick={() => editShow(record.id)}
                      />
                    ) : (null)}
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    {userLogged?.data?.role === 'ADMIN' ? (
                      <DeleteButton
                        size="small"
                        hideText
                        recordItemId={record.id}
                      />
                    ) : (null)}
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <EditAgent
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateAgent
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const { RangePicker } = DatePicker;

  const { tableProps } = useTable<IAgent, HttpError>({
    resource: "agents",
    /*     permanentFilter: [
          {
            field: "__agentId",
            operator: "eq",
            value: agent?.id,
          },
        ], */
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ]
  });

  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("agentactivies.filter.agentId.label")} name="id">
            <Select
              allowClear
              style={{ minWidth: 150 }}
              placeholder={t("agentactivies.filter.agentId.placeholder")}
              options={tableProps?.dataSource?.map(d => {
                return {
                  label: d.displayName,
                  value: d.id
                }
              })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("agents.filter.createdAt.label")} name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("agents.filter.type.label")} name="type">
            <Select
              allowClear
              placeholder={t("agents.filter.type.placeholder")}
              options={[
                {
                  label: t("agents.filter.type.windows"),
                  value: t("agents.filter.type.windows"),
                },
                {
                  label: t("agents.filter.type.mac"),
                  value: t("agents.filter.type.mac"),
                },
                {
                  label: t("agents.filter.type.linux"),
                  value: t("agents.filter.type.linux"),
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("agents.filter.status.label")} name="status">
            <Select
              allowClear
              placeholder={t("agents.filter.status.placeholder")}
              options={[
                {
                  label: t("agents.filter.status.active"),
                  value: "ACTIVE",
                },
                {
                  label: t("agents.filter.status.disabled"),
                  value: "DISABLED",
                },
                {
                  label: t("agents.filter.status.compromised"),
                  value: "COMPROMISED",
                },
                {
                  label: t("agents.filter.status.pending_alarm"),
                  value: "PENDING_ALARM",
                }
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("agents.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
