import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, admin, dashboard, (list)|(create)
p, admin, users, (list)|(create)|(show)|(delete)|(edit)
p, admin, agents, (list)|(create)|(show)|(delete)|(edit)
p, admin, alarms, (list)|(show)|(edit)
p, admin, agentactivies, (list)
p, admin, useractivies, (list)
p, admin, files, (list)|(show)

p, operator, dashboard, (list)
p, operator, alarms, (list)|(show)|(edit)
p, operator, agents, (list)|(show)
p, operator, files, (list)|(show)
`);
