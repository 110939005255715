import React from "react";
import { LoginPageProps, LoginFormTypes } from "@pankod/refine-core";
import {
    Row,
    Col,
    Layout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    CardProps,
    LayoutProps,
    Divider,
    FormProps,
} from "antd";
import { useLogin, useTranslate } from "@pankod/refine-core";

import { layoutStyles, containerStyles, titleStyles } from "./styles";
import { Avatar, Space } from "@pankod/refine-antd";

const { Title } = Typography;

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;

export const LoginPage: React.FC<LoginProps> = ({
    providers,
    contentProps,
    wrapperProps,
    renderContent,
    formProps,
}) => {
    const [form] = Form.useForm<LoginFormTypes>();
    const translate = useTranslate();
    const { mutate: login, isLoading } = useLogin<LoginFormTypes>();

    const CardTitle = (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }}>
            <Avatar
                size={92}
                src={`/images/logo.png`}
            />
            <Title level={3} style={titleStyles}>
                {translate("pages.login.title", "")}
            </Title>
        </div>
    );

    const renderProviders = () => {
        if (providers && providers.length > 0) {
            return (
                <>
                    {providers.map((provider) => {
                        return (
                            <Button
                                key={provider.name}
                                type="ghost"
                                block
                                icon={"EyeFilled"}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: "8px",
                                }}
                                onClick={() =>
                                    console.log('todo')
                                }
                            >
                            </Button>
                        );
                    })}
                    <Divider>{translate("pages.login.divider", "or")}</Divider>
                </>
            );
        }
        return null;
    };

    const CardContent = (
        <Card
            title={CardTitle}
            headStyle={{ borderBottom: 0 }}
            style={containerStyles}
            {...(contentProps ?? {})}
        >
            {renderProviders()}
            <Form<LoginFormTypes>
                layout="vertical"
                form={form}
                onFinish={(values) => login(values)}
                requiredMark={false}
                initialValues={{
                    remember: false,
                }}
                {...formProps}
            >
                <Form.Item
                    name="email"
                    label={translate("pages.login.fields.email", "Email")}
                    rules={[
                        { required: true },
                        {
                            type: "email",
                            message: translate(
                                "pages.login.errors.validEmail",
                                "Invalid email address",
                            ),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={translate(
                            "pages.login.fields.email",
                            "Email",
                        )}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={translate("pages.login.fields.password", "Password")}
                    rules={[{ required: true }]}
                    style={{ marginBottom: "12px" }}
                >
                    <Input
                        type="password"
                        placeholder="●●●●●●●●"
                        size="large"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={isLoading}
                        block
                    >
                        {translate("pages.login.signin", "Sign in")}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );

    return (
        <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    {renderContent ? renderContent(CardContent) : CardContent}
                </Col>
            </Row>
        </Layout>
    );
};
