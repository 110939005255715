import axios from "axios"
import { HttpError } from "@pankod/refine-core";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = "http://localhost:3001/api"

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.data?.code,
            description: error.response?.data?.data?.code,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: any) => {
        // Retrieve the token from local storage
        const token = localStorage.getItem("token");
        // Check if the header property exists
        if (request.headers) {
            // Set the Authorization header if it exists
            request.headers[
                'x-token'
            ] = token;
        } else {
            // Create the headers property if it does not exist
            request.headers = {
                'x-token': token,
            };
        }

        return request;
    },
);

export default axiosInstance;


