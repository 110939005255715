import { useTranslate, useNavigation } from "@pankod/refine-core";
import {
    Typography,
    Timeline,
    useSimpleList,
    AntdList,
    Tooltip,
    Empty,
    Row,
    Col,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { IAgentLog } from "interfaces";
import "./style.less";

dayjs.extend(relativeTime);

type TimelineProps = {
    title?: string;
    resource: {
        id: string,
        resourceName: string;
        field: string
    };

}

export const DetailsAgentBox: React.FC<TimelineProps> = ({
    title,
    resource
}) => {
    const t = useTranslate();

    const { listProps } = useSimpleList<IAgentLog>({
        resource: resource?.resourceName,
        permanentFilter: [
            {
                field: "__" + resource.field,
                operator: "eq",
                value: resource.id,
            },
        ],
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        pagination: {
            pageSize: 6,
            simple: true,
        },
        syncWithLocation: false,
    });

    const { dataSource } = listProps;

    const { Text, Title } = Typography;

    const operationStatusColor = (
        operation: string,
    ): { color: string; text: string } | undefined => {
        switch (operation) {
            case "CREATED":
                return { color: "orange", text: "created" };
            /*             case "2":
                            return { color: "cyan", text: "ready" };
                        case "3":
                            return { color: "green", text: "on the way" };
                        case "4":
                            return { color: "blue", text: "delivered" };
                        case "5":
                            return { color: "red", text: "cancelled" }; */
            default:
                return { color: "red", text: "created" };
            // break;
        }
    };

    const CardTimelineTitle = (
        <>
            <Row>
                <Col xs={12}>
                    <Title level={3}>
                        {title}
                    </Title>
                </Col>
                <Col xs={12}>
                    <Title level={3}>
                        {title}
                    </Title>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {title ? CardTimelineTitle : null}
            {dataSource && dataSource.length > 0 ? (
                <AntdList {...listProps}>
                    <Timeline
                        style={{ marginTop: "18px" }}
                    >
                        {dataSource?.map(({ createdAt, agentDisplayName, operation, onModelType }) => (
                            <Timeline.Item
                                className="timeline__point"
                                key={agentDisplayName}
                                color={operationStatusColor(operation)?.color}
                            >
                                <div
                                    className={`timeline ${operationStatusColor(operation)?.color
                                        }`}
                                >
                                    <Tooltip
                                        overlayInnerStyle={{ color: "#626262" }}
                                        color="rgba(255, 255, 255, 0.3)"
                                        placement="topLeft"
                                        title={dayjs(new Date(0).setUTCSeconds(createdAt)).format("lll")}
                                    >
                                        <Text italic className="createdAt">
                                            {dayjs(new Date(0).setUTCSeconds(createdAt)).fromNow()}
                                        </Text>
                                    </Tooltip>
                                    <Text>
                                        {t(
                                            `dashboard.timeline.operation.${operationStatusColor(operation)?.text
                                            }`,
                                        )}
                                    </Text>
                                </div>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </AntdList>
            ) : (<Empty />)}
        </>
    )
};
