import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  HttpError,
} from "@pankod/refine-core";

import {
  Typography,
  Avatar,
  Row,
  Col,
  Card,
  Space,
  Icons,
  Grid,
  DateField,
  Table,
  List,
  useTable,
  TextField,
  ShowButton,
  Tag,
} from "@pankod/refine-antd";

import {
  IAgent,
  IUserActivity,
  IUserActivityFilterVariables,
  IUser,
  IAgentFilterVariables,
  IUserLog
} from "interfaces";
import "./style.less";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { xl } = useBreakpoint();
  const { queryResult } = useShow<IUser>();

  const user = queryResult?.data?.data as unknown as IUser;

  const props = useTable<
    IAgent,
    HttpError,
    IAgentFilterVariables
  >({
    resource: "agents",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "_id__in",
        operator: "in",
        value: user?.monitoredAgentIds,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: user !== undefined,
    },
    syncWithLocation: false,
  });

  const { tableProps } = useTable<
    IUserActivity,
    HttpError,
    IUserActivityFilterVariables
  >({
    resource: "useractivies",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "userId",
        operator: "eq",
        value: user?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: user !== undefined,
    },
    syncWithLocation: false,
  });

  const operationStatusColor = (
    operation: string,
  ): { color: string; text: string } | undefined => {
    switch (operation) {
      case "CREATED":
        return { color: "orange", text: "created" };
      case "LIST":
        return { color: "cyan", text: "list" };
      case "SHOW":
        return { color: "green", text: "show" };
      case "UPDATE":
        return { color: "pink", text: "update" };
      case "DELETE":
        return { color: "red", text: "delete" };
      case "LOGIN":
        return { color: "geekblue", text: "login" };
      case "UPLOAD_ICON":
        return { color: "volcano", text: "upload_icon" };
      default:
        return { color: "green", text: "" };
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Avatar
                  size={120}
                  src={
                    (user?.avatarUrl &&
                      Array.isArray(user?.avatarUrl) &&
                      user?.avatarUrl[0]?.url) ||
                    "/images/profile.png"
                  }
                ></Avatar>
                <Typography.Title level={3}>
                  {user?.displayName}
                </Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Typography.Text>
                  <Icons.UserOutlined /> {user?.email}
                </Typography.Text>
                <Typography.Text>
                  <Icons.PhoneOutlined /> {user?.phone}
                </Typography.Text>
                <Typography.Text>
                  <Icons.CalendarOutlined />{" "}
                  <DateField
                    value={new Date(0).setUTCSeconds(user?.createdAt)}
                    format="LLL"
                  />
                </Typography.Text>
                <Typography.Text>
                  <Icons.CheckOutlined />{" "}
                  {user?.isActive
                    ? <Tag color={"green"}>{t("users.fields.isActive.true")}</Tag>
                    : <Tag color={"red"}>{t("users.fields.isActive.false")}</Tag>}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            title={t("useractivies.titles.list")}
            pageHeaderProps={{
              style: {
                marginTop: "1em",
              },
            }}
            breadcrumb={null}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                title={t("useractivies.fields.createdAt")}
                render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
                sorter
              />
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("useractivies.fields.userId")}
                render={(value: string, _: IUserLog) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.userAvatarUrl}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 18, fontWeight: 800 }}>
                        {_.userDisplayName}
                      </Text>
                      <Text style={{ fontSize: 10 }}>#{_?.userId}</Text>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="operation"
                dataIndex="operation"
                title={t("useractivies.fields.operation")}
                render={(value: string, _: IUserLog) => (
                  <>
                    <Row>
                      <Tag color={operationStatusColor(value)?.color}>{value + " " + _.onModelType /* + " " + _.onModelRef */}</Tag>
                    </Row>
                    {_?.onModelRef ? (<Row>
                      <Tag color={"#C3C5C5"}>{_.onModelRef}</Tag>
                    </Row>) : (null)}
                  </>
                )}
              />
            </Table>
          </List>
          {user?.role === "OPERATOR" ? (
            <List
              title={t("users.fields.agent")}
              pageHeaderProps={{
                extra: <></>,
                style: {
                  marginTop: "1em",
                },
              }}
              breadcrumb={null}
            >
              <Table {...props.tableProps} rowKey="id">
                <Table.Column
                  key="userId"
                  dataIndex="userId"
                  title={t("agents.fields.agent")}
                  render={(value: string, _: IAgent) => (

                    <div className="product">
                      <Avatar
                        size={{
                          md: 40,
                          lg: 64,
                          xl: 64,
                          xxl: 64,
                        }}
                        src={_?.type === "WINDOWS" ? "/images/windows.png" : (_.type === "MAC" ? "/images/ios.png" : "/images/linux.png")}
                      />
                      <div className="product-text">
                        <Text style={{ fontSize: 14, fontWeight: 800 }}>
                          #{_?.id}
                        </Text>
                        <Text style={{ fontSize: 15 }}>{_?.displayName}</Text>
                      </div>
                    </div>
                  )}
                />
                <Table.Column
                  key="status"
                  dataIndex="status"
                  title={t("agents.fields.status.label")}
                  render={(_, record: IAgent) => {
                    return (
                      <Tag color={record?.status === "ACTIVE" ? "green" : (record.status === "DISABLED" ? "red" : "gold")}>{record?.status}</Tag>
                    )
                  }}
                />
                <Table.Column
                  key="status"
                  dataIndex="status"
                  title={t("agents.fields.configuration")}
                  render={(_, record: IAgent) => {
                    return (
                      <>
                        {record?.backupEnabled ? (
                          <Tag color="blue">BACKUP</Tag>
                        ) : (null)}
                        {record?.networkUploadCheckEnabled ? (
                          <Tag color="blue">NETWORK MONITORING</Tag>
                        ) : (null)}
                        {record?.restoreEnabled ? (
                          <Tag color="blue">FILE MONITORING</Tag>
                        ) : (null)}
                      </>
                    )
                  }}
                />
                <Table.Column<IAgent>
                  title={t("agents.fields.actions")}
                  dataIndex="actions"
                  fixed="right"
                  render={(_, record) => (
                    <Space>
                      <ShowButton
                        resourceName="agents"
                        hideText
                        size="small"
                        recordItemId={record.id}
                      />
                    </Space>
                  )}
                />
              </Table>
              {/*               <Table
                pagination={false}
                dataSource={user?.monitoredAgentIds.map((e) => {
                  return {
                    text: agents?.data.filter((a) => a.id === e)[0].displayName,
                    coordinate: null,
                  };
                })}
              >
                <Table.Column
                  dataIndex="text"
                  title={t("users.fields.agent")}
                />
              </Table> */}
            </List>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
