import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError
} from "@pankod/refine-core";

import {
  List,
  Table,
  Avatar,
  useTable,
  DateField,
  BooleanField,
  Card,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Select,
  FormProps,
  Row,
  Col,
  useDrawerForm,
  CreateButton,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
  Tag,
  Typography
} from "@pankod/refine-antd";

import { IUser, IUserFilterVariables } from "interfaces";
import { CreateUser, EditUser } from "components";
import "./style.less";
import { activeStatusColor } from "utils";

const { Text } = Typography;

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IUser,
    HttpError,
    IUserFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, createdAt, role, isActive } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "role",
        operator: "eq",
        value: role,
      });

      filters.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IUser>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IUser>({
    action: "create",
    resource: "users",
    redirect: false,
  });

  const t = useTranslate();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title={t("users.filter.title")}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            pageHeaderProps={{
              extra: (
                <CreateButton onClick={() => {
                  createShow()
                }}>
                  {t("users.buttons.addUser")}
                </CreateButton>
              ),
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("users.fields.operator")}
                render={(value: string, _: IUser) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.avatarUrl[0]?.url || "/images/profile.png"}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.id}
                      </Text>
                      <Text style={{ fontSize: 15 }}>{_?.displayName}</Text>
                      <Tag style={{ width: "25%", display: "flex", justifyContent: "center" }} color={"blue"}>{t("users.fields.role." + _?.role?.toLocaleLowerCase())}</Tag>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="isActive"
                dataIndex="isActive"
                title={t("users.fields.isActive.label")}
                render={(value) =>
                  <Tag color={activeStatusColor(value)?.color}>{value === true ? t("users.fields.isActive.true") : t("users.fields.isActive.false")}</Tag>
                }
              />
              <Table.Column
                key="email"
                dataIndex="email"
                title={t("users.fields.email")}
              />
              <Table.Column<IUser>
                title={t("users.fields.actions")}
                dataIndex="actions"
                fixed={"right"}
                render={(_, record) => (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                      onClick={() => editShow(record.id)}
                    />
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <DeleteButton
                      size="small"
                      hideText
                      recordItemId={record.id}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <EditUser
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateUser
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const { RangePicker } = DatePicker;

  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("users.filter.search.label")} name="q">
            <Input
              placeholder={t("users.filter.search.placeholder")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("users.filter.createdAt.label")} name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("users.filter.role.label")} name="role">
            <Select
              allowClear
              placeholder={t("users.filter.role.placeholder")}
              options={[
                {
                  label: t("users.filter.role.admin"),
                  value: "ADMIN",
                },
                {
                  label: t("users.filter.role.operator"),
                  value: "OPERATOR",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("users.filter.isActive.label")} name="isActive">
            <Select
              allowClear
              placeholder={t("users.filter.isActive.placeholder")}
              options={[
                {
                  label: t("users.filter.isActive.true"),
                  value: "true",
                },
                {
                  label: t("users.filter.isActive.false"),
                  value: "false",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              {t("users.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
