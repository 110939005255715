import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useUpdate,
  useMany,
  useList
} from "@pankod/refine-core";

import {
  List,
  Table,
  Avatar,
  useTable,
  DateField,
  BooleanField,
  Card,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Select,
  FormProps,
  Row,
  Col,
  useDrawerForm,
  CreateButton,
  Space,
  DeleteButton,
  EditButton,
  ShowButton,
  Tag,
  TextField,
  Menu,
  Dropdown,
  Typography
} from "@pankod/refine-antd";

import { IAgent, IAlarm, IAlarmFilterVariables } from "interfaces";
import { EditAlarm } from "components/alarms";

const { Text, Title } = Typography;

export const AlarmsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IAlarm,
    HttpError,
    IAlarmFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { agentId, status, createdAt } = params;

      filters.push({
        field: "agentId",
        operator: "eq",
        value: agentId !== "" ? agentId : undefined,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const { data: agents } = useList<IAgent>({
    resource: "agents",
    config: {
      sort: [{ order: "desc", field: "createdAt" }],
    },
  });

  if (tableProps?.dataSource && agents) {
    tableProps.dataSource = tableProps?.dataSource?.map((d: IAlarm) => {
      d.agent = agents?.data?.filter(e => e.id === d?.agentId)[0] || null
      return d
    })
  }

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<IAlarm>({
    action: "edit",
    resource: "alarms",
    redirect: false,
  });

  const t = useTranslate();
  const { RangePicker } = DatePicker;

  const getImageByReason = (reason: string) => {
    switch (reason) {
      case "NETWORK:LIMIT-EXCEEDING":
        return "https://cdn-icons-png.flaticon.com/512/4330/4330810.png"
      default:
        return "https://cdn-icons-png.flaticon.com/512/3442/3442207.png"
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title={t("agents.filter.title")}>
            <Form layout="vertical" {...searchFormProps}>
              <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={12}>
                  <Form.Item label={t("alarms.filter.agentId.label")} name="agentId">
                    <Select
                      allowClear
                      style={{ minWidth: 150 }}
                      placeholder={t("agentactivies.filter.agentId.placeholder")}

                    >
                      {agents?.data?.map((a: IAgent) => (
                        <option key={a.id} value={a.id}>
                          <div>{a.displayName}</div>
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={12}>
                  <Form.Item label={t("alarms.filter.createdAt.label")} name="createdAt">
                    <RangePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                  <Form.Item label={t("alarms.filter.status.label")} name="status">
                    <Select
                      allowClear
                      placeholder={t("alarms.filter.status.placeholder")}
                      options={[
                        {
                          label: t("alarms.filter.status.confirmed"),
                          value: "CONFIRMED",
                        },
                        {
                          label: t("alarms.filter.status.rejected"),
                          value: "REJECTED",
                        },
                        {
                          label: t("alarms.filter.status.pending"),
                          value: "PENDING",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                  <Form.Item>
                    <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                      {t("agents.filter.submit")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            pageHeaderProps={{
              extra: (
                <></>
              ),
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("alarms.fields.alarm")}
                render={(value: string, _: IAlarm) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={getImageByReason(_?.reason)}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 12, fontWeight: 600 }}>
                        #{_?.id}
                      </Text>
                      <Tag style={{ width: "45%", display: "flex", justifyContent: "center" }} color={_?.status === "CONFIRMED" ? "red" : (_?.status === "REJECTED" ? "blue" : "gold")}>{t("alarms.fields.status." + _?.status.toLowerCase())}</Tag>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="reason"
                dataIndex="reason"
                title={t("alarms.fields.reason")}
              />
              <Table.Column
                key="id"
                dataIndex="id"
                title={t("agents.fields.agent")}
                render={(value: string, _: IAlarm) => (

                  <div className="product">
                    <Avatar
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.agent?.type === "WINDOWS" ? "/images/windows.png" : (_.agent?.type === "MAC" ? "/images/ios.png" : "/images/linux.png")}
                    />
                    <div className="product-text">
                      <Text style={{ fontSize: 14, fontWeight: 800 }}>
                        #{_?.agent?.id}
                      </Text>
                      <Text style={{ fontSize: 15 }}>{_?.agent?.displayName}</Text>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                title={t("alarms.fields.createdAt")}
                render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
                sorter
              />
              <Table.Column<IAlarm>
                title={t("table.actions")}
                key="actions"
                render={(record) => (
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => editShow(record.id)}
                  />
                )}
                fixed="right"
              />
            </Table>
          </List>
        </Col>
      </Row>
      <EditAlarm
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};