import { useTranslate } from "@pankod/refine-core";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    Select,
    ButtonProps,
    Grid,
    Create,
    InputNumber,
    Space,
    Button,
    Row,
    Icons,
    Typography,
} from "@pankod/refine-antd";

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from "react";

type CreateAgentProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateAgent: React.FC<CreateAgentProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [form] = Form.useForm();

    const handleApiKey = () => {
        form.setFieldsValue({
            apiKey: Math.random().toString(36).slice(-8)
        });
    };

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Create
                saveButtonProps={{
                    ...saveButtonProps,
                    onClick: () => form.submit(),
                }}
                pageHeaderProps={{ extra: null }}
                resource="agents"
            >
                <Form {...formProps} layout="vertical" form={form}>
                    <Form.Item
                        label={t("agents.fields.type.label")}
                        name="type"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: t("agents.fields.type.windows"),
                                    value: t("agents.fields.type.windows")
                                },
                                {
                                    label: t("agents.fields.type.mac"),
                                    value: t("agents.fields.type.mac")
                                },
                                {
                                    label: t("agents.fields.type.linux"),
                                    value: t("agents.fields.type.linux")
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.displayName")}
                        name="displayName"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.status.label")}
                        name="status"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: t("agents.fields.status.active"),
                                    value: "ACTIVE",
                                },
                                {
                                    label: t("agents.fields.status.disabled"),
                                    value: "DISABLED",
                                }/* ,
                                {
                                    label: t("agents.fields.status.compromised"),
                                    value: "COMPROMISED",
                                },
                                {
                                    label: t("agents.filter.status.pending_alarm"),
                                    value: "PENDING_ALARM",
                                } */
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.restoreEnabled.label")}
                        name="restoreEnabled"
                        rules={[
                            { required: true }
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("agents.fields.restoreEnabled.enable")}</Radio>
                            <Radio value={false}>{t("agents.fields.restoreEnabled.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.backupEnabled.label")}
                        name="backupEnabled"
                        rules={[
                            { required: true }
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("agents.fields.backupEnabled.enable")}</Radio>
                            <Radio value={false}>{t("agents.fields.backupEnabled.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.backupStorageLimit")}
                        name="backupStorageLimit"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber min={1} addonAfter={" " + t("agents.fields.backupStorageLimitMeasure")} />
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.networkUploadCheckEnabled.label")}
                        name="networkUploadCheckEnabled"
                        rules={[
                            { required: true }
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("agents.fields.networkUploadCheckEnabled.enable")}</Radio>
                            <Radio value={false}>{t("agents.fields.networkUploadCheckEnabled.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.networkUploadSamplingInterval")}
                        name="networkUploadSamplingInterval"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber min={1} addonAfter={" " + t("agents.fields.networkUploadSamplingIntervalMeasure")} />
                    </Form.Item>

                    <Form.Item
                        label={t("agents.fields.networkUploadLimit")}
                        name="networkUploadLimit"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber min={1} addonAfter={" " + t("agents.fields.networkUploadLimitMeasure")} />
                    </Form.Item>
                    <Form.Item
                        label={t("agents.fields.networkUploadPeriod")}
                        name="networkUploadPeriod"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber min={1} addonAfter={" " + t("agents.fields.networkUploadPeriodMeasure")} />
                    </Form.Item>
                    <Typography.Text strong>
                        {t("agents.fields.hooks.label")}
                    </Typography.Text>
                    <Form.List
                        name="hooks"
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'type']}
                                            rules={[{ required: true, message: t("agents.fields.hooks.errorType") }]}
                                        >
                                            <Select
                                                style={{ minWidth: 150 }}
                                                options={[
                                                    {
                                                        label: t("agents.fields.hooks.email"),
                                                        value: t("agents.fields.hooks.email"),
                                                    },
                                                    {
                                                        label: t("agents.fields.hooks.sms"),
                                                        value: t("agents.fields.hooks.sms"),
                                                    },
                                                    {
                                                        label: t("agents.fields.hooks.https"),
                                                        value: t("agents.fields.hooks.https"),
                                                    }
                                                ]}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            rules={[{ required: true, message: t("agents.fields.hooks.errorValue") }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {t("agents.fields.addField")}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Row gutter={8}>
                        <Form.Item
                            label={t("agents.fields.apiKey")}
                            name="apiKey"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    min: 8
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: "30px", marginLeft: "20px", height: "100%" }}
                        >
                            <Button
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                                onClick={handleApiKey}
                            >
                                {t("agents.fields.generateApiKey")}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Create>
        </Drawer>
    );
};

function useFocusEffect(arg0: any) {
    throw new Error("Function not implemented.");
}
