import React, { useEffect } from "react";
import { Refine } from "@pankod/refine-core";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import routerProvider from "@pankod/refine-react-router-v6";
import {
    Icons,
    ConfigProvider,
    ErrorComponent,
    Layout
} from "@pankod/refine-antd";
import jsonServerDataProvider from "dataProvider"

import it_IT from "antd/lib/locale/it_IT"
import { authProvider } from "authProvider";
import dayjs from "dayjs";

import "styles/antd.less";

import { DashboardPage } from "./pages/dashboard";
import { AuthPage } from "./pages/auth";
import { UserList, UserShow } from "./pages/users";
import { useTranslation } from "react-i18next";
import { Header, Title, OffLayoutArea } from "components";

import { newEnforcer } from "casbin";
import { adapter, model } from "accessControl";

import axiosInstance from "httpClient";
import { notificationProvider } from "notificationProvider";
import { AgentList, AgentShow } from "pages/agents";
import { AlarmsList } from "pages/alarms";
import { AgentLogList } from "pages/logAgents";
import { UserLogList } from "pages/logUser";
import { FilesList } from "pages/files";
import { Sider } from "components/sider";

const App: React.FC = () => {
    const API_URL = "http://localhost:3001/api/admin"; // process.env.API_URL as string;
    const { t, i18n } = useTranslation();

    const dataProvider = jsonServerDataProvider(API_URL, axiosInstance, t);

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const locale = i18nProvider.getLocale();

    useEffect(() => {
        if (locale === "it") {
            dayjs.locale("it");
        } else {
            dayjs.locale("en");
        }
    }, [locale]);

    return (
        <RefineKbarProvider>
            <ConfigProvider locale={locale === "it" ? it_IT : undefined}>
                <Refine
                    accessControlProvider={{
                        can: async ({ resource, action }) => {
                            const role = localStorage.getItem("role") ?? "operator"
                            const enforcer = await newEnforcer(model, adapter);
                            const can = await enforcer.enforce(role, resource, action);

                            return Promise.resolve({
                                can,
                            });
                        },
                    }}
                    authProvider={authProvider(t)}
                    routerProvider={routerProvider}
                    dataProvider={dataProvider}
                    i18nProvider={i18nProvider}
                    OffLayoutArea={OffLayoutArea}
                    DashboardPage={DashboardPage}
                    notificationProvider={notificationProvider}
                    LoginPage={() => (
                        <AuthPage
                            type="login"
                        />
                    )}
                    Title={Title}
                    Header={Header}
                    Layout={Layout}
                    Sider={Sider}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: false
                    }}
                    resources={[
                        {
                            name: "users",
                            list: UserList,
                            show: UserShow,
                            icon: <Icons.UsergroupAddOutlined style={{ fontSize: "24px" }} />,
                        },
                        {
                            name: "agents",
                            list: AgentList,
                            show: AgentShow,
                            icon: <Icons.BookOutlined style={{ fontSize: "24px" }} />,
                        },
                        {
                            name: "alarms",
                            list: AlarmsList,
                            icon: <Icons.WarningOutlined style={{ fontSize: "24px" }} />,
                        },
                        {
                            name: "files",
                            list: FilesList,
                            icon: <Icons.FileProtectOutlined style={{ fontSize: "24px" }} />,
                        },
                        {
                            name: "agentactivies",
                            list: AgentLogList,
                            icon: <Icons.UnorderedListOutlined style={{ fontSize: "24px" }} />,
                        },
                        {
                            name: "useractivies",
                            list: UserLogList,
                            icon: <Icons.UnorderedListOutlined style={{ fontSize: "24px" }} />,
                        }
                    ]}
                    catchAll={<ErrorComponent />}
                />
            </ConfigProvider>
        </RefineKbarProvider>
    );
};

export default App;
