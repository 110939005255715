import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import {
    DataProvider,
    CrudOperators,
    CrudFilters,
    CrudSorting,
} from "@pankod/refine-core";

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "gte":
        case "lte":
            return `__${operator}`;
        case "contains":
            return "__like";
        case "eq":
        default:
            return "";
    }
};

const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const _sort: string[] = [];
        const _order: string[] = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        return {
            _sort,
            _order,
        };
    }

    return;
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};

    if (filters) {
        filters.map((filter) => {
            if (filter.operator === "or" || filter.operator === "and") {
                throw new Error(
                    `[@pankod/refine-simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`,
                );
            }

            if ("field" in filter) {
                const { field, operator, value } = filter;

                if (field === "q") {
                    queryFilters[field] = value;
                    return;
                }

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};

const JsonServer = (
    apiUrl: string,
    httpClient: AxiosInstance,
    t: any
): Omit<
    Required<DataProvider>,
    "createMany" | "updateMany" | "deleteMany"
> => ({
    getList: async ({
        resource,
        hasPagination = true,
        pagination = { current: 1, pageSize: 10 },
        filters,
        sort,
    }) => {
        const url = `${apiUrl}/${resource}`;

        const { current = 1, pageSize = 10 } = pagination ?? {};

        const queryFilters = generateFilter(filters);

        const query: {
            __start?: number;
            __end?: number;
            __sort?: string;
            __order?: string;
        } = hasPagination
            ? {
                  __start: (current - 1) * pageSize,
                  __end: current * pageSize,
              }
            : {};

        const generatedSort = generateSort(sort);
        if (generatedSort) {
            const { _sort, _order } = generatedSort;
            query.__sort = _sort.join(",");
            query.__order = _order.join(",");
        }

        try {
            const { data } = await httpClient.get(
                `${url}?${stringify(query)}&${stringify(queryFilters)}`,
            );

            return {
                data: data?.data,
                total: data?.meta?.totalCount || 0
            };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }

    },

    getMany: async ({ resource, ids }) => {
        try {
            const { data } = await httpClient.get(
                `${apiUrl}/${resource}?_id__in=${ids.join(",")}`,
            );

            return {
                data: data?.data,
                total: data?.meta?.totalCount || 0
            };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }
    },

    create: async ({ resource, variables }) => {
        try {
            const url = `${apiUrl}/${resource}`;

            const { data } = await httpClient.post(url, variables);

            return {
                data,
            };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }
    },

    update: async ({ resource, id, variables }) => {
        try {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.put(url, variables);

            return {
                data,
            };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }
    },

    getOne: async ({ resource, id }) => {
        try {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.get(url);

            return { data: data?.data };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }
    },

    deleteOne: async ({ resource, id, variables }) => {
        try {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.delete(url, {
                data: variables,
            });

            return {
                data,
            };
        } catch (e: any) {
            if (e.response?.data?.data?.code && e.response.data.data.message) {
                return Promise.reject({
                    status: e.response.status,
                    message: t("notifications.code." + e.response.data.data.code),
                    description: ""
                })
            } else {
                return Promise.reject();
            }
        }
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const { _sort, _order } = generatedSort;
                const sortQuery = {
                    __sort: _sort.join(","),
                    __order: _order.join(","),
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});

export default JsonServer;
