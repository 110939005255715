import { Row, Col, Typography, Card, Tag, Divider, Progress, TextField, AntdList, useSimpleList } from "@pankod/refine-antd";
import { HttpError, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { IAgent } from "interfaces";

const { Text, Title } = Typography;

export const DashboardPage: React.FC = () => {
    const t = useTranslate();
    const userLogged = useGetIdentity()
    const { listProps } = useSimpleList<
        IAgent,
        HttpError
    >({
        resource: "agents",
        permanentFilter: [
            {
                field: "_id__in",
                operator: "eq",
                value: userLogged?.data?.role === "ADMIN" ? undefined : userLogged?.data?.monitoredAgentIds || [],
            },
        ],
        hasPagination: false
    });

    const calcFormat = (percent: number | undefined, agent: IAgent) => `${agent?.backupFilesSize} MB`

    return (
        <>
            <AntdList
                grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 4,
                }}
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                    padding: "16px 24px"
                }}
                {...listProps}
                renderItem={(agent: IAgent) => (
                    <Card bordered={false} style={{ height: "98%", width: "95%" }}>
                        <div style={{ height: '98%', width: '95%' }}>
                            <Row>
                                <Col xs={24}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}>
                                        <Text strong>
                                            {agent?.displayName}
                                        </Text>
                                        <Tag color={agent?.status === "ACTIVE" ? "green" : (agent?.status === "DISABLED" ? "red" : "gold")} style={{ fontSize: "10px" }}>{t("agents.fields.status." + agent?.status.toLowerCase())}</Tag>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Progress width={230} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }} type="dashboard" percent={(((agent?.backupFilesSize || 1) / (agent?.backupStorageLimit || 1)) * 100) || 0} format={(percent) => calcFormat(percent, agent)} />
                                    <TextField style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px" }}
                                        value={t("agents.fields.diskSpace")}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col xs={12}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}>
                                        <Text style={{ fontSize: "12px" }}>{t("agents.fields.savedFiles")}</Text>
                                        <Text strong style={{ fontSize: "14px" }}>{agent?.backupFilesCounter}</Text>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}>
                                        <Text style={{ fontSize: "12px" }}>{t("agents.fields.pending_alarms")}</Text>
                                        <Text strong style={{ fontSize: "14px" }}>{agent?.alarmsPendingCounter}/{agent?.alarmsSentCounter}</Text>
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            {agent?.backupEnabled ? (
                                <Tag color="blue">BACKUP</Tag>
                            ) : (null)}
                            {agent?.networkUploadCheckEnabled ? (
                                <Tag color="cyan">NETWORK MONITORING</Tag>
                            ) : (null)}
                            {agent?.restoreEnabled ? (
                                <Tag color="volcano">FILE MONITORING</Tag>
                            ) : (null)}
                        </div>
                    </Card>

                )}
            />

        </>
    );
};
