import { useOne, useTranslate, useUpdate } from "@pankod/refine-core";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Select,
    ButtonProps,
    Grid,
    Typography,
    Row,
    Col,
    Card,
    Divider,
    Table,
    TextField,
    List,
    Tag,
    DateField,
    Radio,
    SaveButton
} from "@pankod/refine-antd";

import { IAlarm, IUser } from "interfaces";
import { useEffect } from "react";

const it = require("dayjs/locale/it")

type EditAlarmProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const EditAlarm: React.FC<EditAlarmProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [form] = Form.useForm();

    const { data } = useOne<IUser>({
        resource: "users",
        id: formProps?.initialValues?.closedBy || ""
    })

    const user = data?.data

    const { mutate } = useUpdate<IAlarm>();

    useEffect(() => {
        if (formProps?.initialValues) {
            form.setFieldsValue({ ...formProps.initialValues });
        }
    }, [formProps.initialValues])

    const handleUpdateStatus = (value: any) => {
        mutate({
            resource: "alarms",
            id: formProps.initialValues?.id,
            values: { status: value?.status },
        });
    }

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Form
                onFinish={handleUpdateStatus}>
                <Row gutter={[16, 16]}>
                    <Col xl={24} lg={24} xs={24}>
                        <Card bordered={false} style={{ height: "100%" }}>
                            <Typography>
                                <Typography.Title level={3}>
                                    {t("alarms.alarm")}
                                </Typography.Title>
                                <Typography.Paragraph style={{ marginTop: "36px" }}>
                                    <Row gutter={8}>
                                        <Col span={4}>
                                            <Typography.Text strong>
                                                {t("alarms.fields.status.label")} :
                                            </Typography.Text>
                                        </Col>
                                        <Col span={4}>
                                            <Tag color={formProps.initialValues?.status === "CONFIRMED" ? "red" : (formProps.initialValues?.status === "REJECTED" ? "blue" : "gold")}>{t("alarms.fields.status." + formProps?.initialValues?.status?.toLowerCase())}</Tag>
                                        </Col>
                                    </Row>
                                    {
                                        formProps.initialValues?.status === "PENDING" ? (
                                            <>
                                                <Row style={{ marginTop: "36px" }}>
                                                    <Col span={24}>
                                                        <Typography.Text strong>
                                                            {t("alarms.fields.statusEdit.updateStatus")} :
                                                        </Typography.Text>
                                                        <Form.Item
                                                            name="status"
                                                            rules={[
                                                                { required: true }
                                                            ]}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value={"CONFIRMED"}>{t("alarms.fields.statusEdit.confirmed")}</Radio>
                                                                <Radio value={"REJECTED"}>{t("alarms.fields.statusEdit.rejected")}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Typography.Paragraph style={{ marginTop: "36px" }}>
                                                    <Row gutter={8}>
                                                        <Col span={6}>
                                                            <Typography.Text strong>
                                                                {t("alarms.fields.closedBy")} :
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col span={18}>
                                                            <div style={{ border: "1px solid", borderRadius: "25px", textAlign: "center", width: "100%" }}>{user?.displayName}</div>
                                                        </Col>
                                                    </Row>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph style={{ marginTop: "36px" }}>
                                                    <Row gutter={8}>
                                                        <Col span={8}>
                                                            <Typography.Text strong>
                                                                {t("alarms.fields.updatedAt")} :
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col span={16}>
                                                            <DateField value={new Date(0).setUTCSeconds(formProps.initialValues?.updatedAt)} locales={it} format="LLL" />
                                                        </Col>
                                                    </Row>
                                                </Typography.Paragraph>
                                            </>
                                        )
                                    }
                                </Typography.Paragraph>
                                <Divider />
                                <Typography.Paragraph style={{ marginTop: "36px" }}>
                                    <Row gutter={8}>
                                        <Col span={4}>
                                            <Typography.Text strong>
                                                {t("alarms.fields.reason")} :
                                            </Typography.Text>
                                        </Col>
                                        <Col span={10}>
                                            <div style={{ border: "1px solid", borderRadius: "25px", textAlign: "center", width: "100%" }}>{formProps.initialValues?.reason}</div>
                                        </Col>
                                    </Row>
                                </Typography.Paragraph>
                                <Typography.Paragraph style={{ marginTop: "36px" }}>
                                    <Row gutter={8}>
                                        <Col span={5}>
                                            <Typography.Text strong>
                                                {t("alarms.fields.createdAt")} :
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <DateField value={new Date(0).setUTCSeconds(formProps.initialValues?.createdAt)} locales={it} format="LLL" />
                                        </Col>
                                    </Row>
                                </Typography.Paragraph>
                                <Divider />
                                <Typography.Text strong>
                                    {t("alarms.fields.info.label")}
                                </Typography.Text>
                                <List
                                    title={""}
                                    pageHeaderProps={{
                                        extra: <></>,
                                    }}
                                    breadcrumb={null}
                                >
                                    <Table
                                        pagination={false} dataSource={formProps.initialValues?.info || []}
                                        rowKey="type"
                                    >
                                        <Table.Column
                                            dataIndex={"type"}
                                            title={t("alarms.fields.hooks.type")}
                                            render={(value: string) => {
                                                return (
                                                    <TextField
                                                        value={value}
                                                    />
                                                );
                                            }}
                                        />
                                        <Table.Column
                                            dataIndex={"value"}
                                            title={t("alarms.fields.hooks.value")}
                                            render={(value: string) => {
                                                return (
                                                    <Typography.Text ellipsis={true} style={{ wordBreak: "break-word", whiteSpace: "break-spaces", width: "80%" }}>
                                                        {value}
                                                    </Typography.Text>
                                                );
                                            }}
                                        />
                                    </Table>
                                </List>
                                <Typography.Text strong>
                                    {t("alarms.fields.hooks.label")}
                                </Typography.Text>
                                <List
                                    title={""}
                                    pageHeaderProps={{
                                        extra: <></>,
                                    }}
                                    breadcrumb={null}
                                >
                                    <Table pagination={false} dataSource={formProps.initialValues?.hooks || []} rowKey="type">
                                        <Table.Column
                                            dataIndex={"type"}
                                            title={t("alarms.fields.hooks.type")}
                                            render={(value: string) => {
                                                return (
                                                    <TextField
                                                        value={value}
                                                    />
                                                );
                                            }}
                                        />
                                        <Table.Column
                                            dataIndex={"value"}
                                            title={t("alarms.fields.hooks.value")}
                                            render={(value: string) => {
                                                return (
                                                    <TextField
                                                        value={value}
                                                    />
                                                );
                                            }}
                                        />
                                    </Table>
                                </List>
                            </Typography>
                            {formProps.initialValues?.status === "PENDING" ? <Form.Item style={{ marginTop: "10px" }} wrapperCol={{ offset: 18 }}>
                                <SaveButton htmlType="submit" />
                            </Form.Item> : (null)}
                        </Card>
                    </Col>
                </Row>
            </Form>
            {/*             <Edit
                saveButtonProps={{
                    ...saveButtonProps,
                    onClick: () => form.submit(),
                }}
                pageHeaderProps={{ extra: null }}
                resource="alarms"
                breadcrumb={""}
                goBack={null}
            >
                <Form layout="vertical" {...formProps} form={form}>
                    <Form.Item
                        label={t("alarms.fields.reason")}
                        name="reason"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label={t("alarms.fields.status.label")}
                        name="status"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            disabled={true}
                            options={[
                                {
                                    label: t("alarms.fields.status.pending"),
                                    value: t("alarms.fields.status.pending")
                                },
                                {
                                    label: t("alarms.fields.status.confirmed"),
                                    value: t("alarms.fields.status.confirmed")
                                },
                                {
                                    label: t("alarms.fields.status.rejected"),
                                    value: t("alarms.fields.status.rejected")
                                }
                            ]}
                        />
                    </Form.Item>
                    {formProps.initialValues?.status === "CONFIRMED" ? <Form.Item
                        label={t("alarms.fields.reason")}
                        name="reason"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item> : null}
                    <Typography.Text strong>
                        {t("agents.fields.hooks.label")}
                    </Typography.Text>
                    <Form.List
                        name="hooks"
                    >
                        {(fields) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'type']}
                                            rules={[{ required: true, message: t("agents.fields.hooks.errorType") }]}
                                        >
                                            <Select
                                                disabled={true}
                                                style={{ minWidth: 150 }}
                                                options={[
                                                    {
                                                        label: t("agents.fields.hooks.email"),
                                                        value: t("agents.fields.hooks.email"),
                                                    },
                                                    {
                                                        label: t("agents.fields.hooks.sms"),
                                                        value: t("agents.fields.hooks.sms"),
                                                    },
                                                    {
                                                        label: t("agents.fields.hooks.https"),
                                                        value: t("agents.fields.hooks.https"),
                                                    }
                                                ]}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            rules={[{ required: true, message: t("agents.fields.hooks.errorValue") }]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </>
                        )}
                    </Form.List>
                    <Typography.Text strong>
                        {t("alarms.fields.info.label")}
                    </Typography.Text>
                    <Form.List
                        name="info"
                    >
                        {(fields) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => {
                                    return <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'type']}
                                            rules={[{ required: true, message: t("agents.fields.info.errorType") }]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                        <Popover placement="top" title={""} content={form.getFieldValue("info")[key].value} trigger="hover">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true, message: t("agents.fields.info.errorValue") }]}
                                            >
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Popover>
                                    </Space>
                                })}
                            </>
                        )}
                    </Form.List>
                </Form>
            </Edit> */}
        </Drawer >
    );
};