import { useState, useEffect } from "react";
import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useTranslate,
} from "@pankod/refine-core";

import {
  AntdLayout,
  Menu,
  Icons,
  Dropdown,
  Input,
  Avatar,
  Typography,
  Space,
  Grid,
  Row,
  Col,
  AutoComplete,
  useDrawerForm,
  Button,
} from "@pankod/refine-antd";

import RefineReactRouter from "@pankod/refine-react-router-v6";

import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const { SearchOutlined, DownOutlined } = Icons;
const { Text } = Typography;
const { useBreakpoint } = Grid;

import { IUser } from "interfaces";
import "./style.less";
import { EditUser } from "components/users";
import { EditHeaderUser } from "./edit";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const t = useTranslate();

  const currentLocale = locale();

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IUser>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  useEffect(() => {
    setOptions([]);
  }, [value]);

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages ?? [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "Italian"}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <AntdLayout.Header
        style={{
          padding: "0px 24px",
          height: "64px",
          backgroundColor: "#FFF",
        }}
      >
        <Row align="middle" justify={screens.sm ? "space-between" : "end"}>
          <Col xs={0} sm={12}>
            {/*             <AutoComplete
              dropdownClassName="header-search"
              style={{ width: "100%", maxWidth: "550px" }}
              options={options}
              filterOption={false}
              onSearch={debounce((value: string) => setValue(value), 300)}
            >
              <Input
                size="large"
                placeholder={t("search.placeholder")}
                suffix={<SearchOutlined />}
              />
            </AutoComplete> */}
          </Col>
          <Col>
            <Space size="middle">
              {/*               <Dropdown overlay={menu}>
                <a
                  style={{ color: "inherit" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    <Avatar
                      size={16}
                      src={`/images/flags/${currentLocale}.svg`}
                    />
                    <div
                      style={{
                        display: screens.lg ? "block" : "none",
                      }}
                    >
                      {currentLocale === "en" ? "English" : "Italian"}
                      <DownOutlined
                        style={{
                          fontSize: "12px",
                          marginLeft: "6px",
                        }}
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown> */}
              <Text ellipsis strong>
                {user?.displayName}
              </Text>
              <Button
                style={{
                  border: "none",
                }}
                onClick={() => editShow(user.id)}
              >
                <Avatar
                  size="large"
                  src={
                    (user?.avatarUrl?.length > 0 &&
                      user.avatarUrl[0]?.url) ||
                    "/images/profile.png"
                  }
                  alt={user?.displayName}
                />
              </Button>
            </Space>
          </Col>
        </Row>
      </AntdLayout.Header>
      <EditHeaderUser
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
