import "./style.less";
import { BikeWhiteIcon } from "components";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    return (
        <div className="logo">
            {collapsed ? (
                <img src="/images/logo.png" alt="Vault53" style={{ width: "24px" }} />
            ) : (
                <img src="/images/logo.png" alt="Vault53" style={{ width: "48px" }} />
            )}
        </div>
    );
};

{}
