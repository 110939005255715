import { AuthProvider } from "@pankod/refine-core";
import httpClient from "httpClient"

export const TOKEN_KEY = "token";
export const ROLE_KEY = "role";
export const USER_KEY = "userId";

export const authProvider = (t: any) => {

    const provider: AuthProvider = {
        login: async ({ email, password }) => {
            
            const url = `/admin/users/login`;

            try {
                const { data } = await httpClient.post(
                    `${url}`, { email, password }
                );
                        
                localStorage.setItem(TOKEN_KEY, data.data.token.token);
                localStorage.setItem(ROLE_KEY, data.data.profile.role.toLowerCase());
                localStorage.setItem(USER_KEY, data.data.profile.id);

                return Promise.resolve();
            } catch (e: any) {
                if (e.response?.data?.data?.code && e.response.data.data.message) {
                    return Promise.reject({
                        status: e.response.status,
                        message: t("notifications.code." + e.response.data.data.code),
                        description: ""
                    })
                } else {
                    return Promise.reject();
                }
            }

        },
        logout: () => {
            
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(ROLE_KEY);
            localStorage.removeItem(USER_KEY);

            delete httpClient.defaults.headers.common["x-token"]
            return Promise.resolve();
        },
        checkError: (error) => {
            if (error?.status === 403) {
                localStorage.clear()

                return Promise.reject();
            } 
        
            return Promise.resolve()
            
        },
        checkAuth: () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (token) {
                return Promise.resolve();
            }

            return Promise.reject();
        },
        getPermissions: () => Promise.resolve(),
        getUserIdentity: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (!token) {
                return Promise.reject();
            }

            const userId = localStorage.getItem(USER_KEY);
            const url = `/admin/users/${userId}`;

            try {
                const { data } = await httpClient.get(
                    `${url}`
                );
                        
                return Promise.resolve(data?.data || null);
            } catch (e: any) {
                if (e.response?.data?.data?.code && e.response.data.data.message) {
                    return Promise.reject({
                        status: e.response.status,
                        message: t("notifications.code." + e.response.data.data.code),
                        description: ""
                    })
                } else {
                    return Promise.reject();
                }
            }
        },
    }

    return provider
};
