import { useTranslate, useApiUrl, useList, useGetIdentity } from "@pankod/refine-core";

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  Button,
  Icons,
  UploadFile,
} from "@pankod/refine-antd";

import { IAgent } from "interfaces";
import { useEffect, useState } from "react";
import { timezones } from "utils";

const { Text } = Typography;

type EditUserProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const EditUser: React.FC<EditUserProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const userLogged = useGetIdentity()
  const [passwordShown, setPasswordShown] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      status: "done",
      url: "",
    },
  ]);
  const [form] = Form.useForm();

  const { data: agents } = useList<IAgent>({
    resource: "agents",
    config: {
      sort: [{ order: "desc", field: "createdAt" }],
    },
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (formProps?.initialValues) {
      form.setFieldsValue({ ...formProps.initialValues });
    }
  }, [formProps.initialValues])

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () => {
            if (!form.getFieldValue("timezone")) {
              form.setFieldsValue({ timezone: "Europe/Rome" });
            }
            form.submit()
          },
        }}
        pageHeaderProps={{ extra: null }}
        resource="users"
      >
        <Form {...formProps} layout="vertical" form={form}>
          <Form.Item label={t("users.fields.avatar.label")}>
            <Form.Item
              name="avatarUrl"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/icons/upload`}
                headers={{
                  "x-token": `${localStorage.getItem("token")}`,
                }}
                listType="picture"
                maxCount={1}
                accept=".png"
                fileList={fileList}
                onChange={(info) => {
                  let newFileList = [...info.fileList];

                  newFileList = newFileList.slice(-2);

                  newFileList = newFileList.map((file) => {
                    if (file.response) {
                      file.url = file.response.url;
                    }
                    return file;
                  });

                  setFileList(newFileList);
                }}
              >
                <Space direction="vertical" size={2}>
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "256px",
                    }}
                    src={
                      (formProps?.initialValues?.avatarUrl &&
                        Array.isArray(formProps?.initialValues?.avatarUrl) &&
                        formProps?.initialValues?.avatarUrl[0]?.url) ||
                      "/images/upload-logo.png"
                    }
                    alt="Store Location"
                  />
                  <Text
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginTop: "8px",
                    }}
                  >
                    {t("users.fields.avatar.description")}
                  </Text>
                  <Text style={{ fontSize: "12px" }}>
                    {t("products.fields.images.validation")}
                  </Text>
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("users.fields.firstName")}
            name="displayName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.language.label")}
            name="lang"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: t("users.fields.language.en"),
                  value: t("users.fields.language.en"),
                },
                {
                  label: t("users.fields.language.it"),
                  value: t("users.fields.language.it"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("users.fields.timezone")}
            name="timezone"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={formProps?.initialValues?.timezone}
            >
              {timezones.map((a: string, idx: number) => (
                <option key={idx} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("users.fields.role.label")}
            name="role"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: t("users.fields.role.admin"),
                  value: "ADMIN",
                },
                {
                  label: t("users.fields.role.operator"),
                  value: "OPERATOR",
                },
              ]}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: t(
                  "pages.login.errors.validEmail",
                  "Invalid email address"
                ),
              },
            ]}
          >
            <Input
              size="middle"
              placeholder={t("pages.login.fields.email", "Email")}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("users.fields.gsm", "Phone")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.isActive.label")}
            name="isActive"
            rules={[{ required: formProps?.initialValues?.id !== userLogged?.data?.id }]}
          >
            <Radio.Group
              disabled={formProps?.initialValues?.id === userLogged?.data?.id}
            >
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
          {formProps?.initialValues?.role === "OPERATOR" ? (
            <Form.Item
              label={t("users.fields.agent")}
              name="monitoredAgentIds"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("users.fields.placeholderAgent")}
                defaultValue={agents?.data?.filter((a: IAgent) => formProps?.initialValues?.monitoredAgentIds.includes(a.id)).map((a: IAgent) => a.displayName) || []}
                disabled={formProps?.initialValues?.id === userLogged?.data?.id}
              >
                {agents?.data?.map((a: IAgent) => (
                  <option key={a.id} value={a.id}>
                    {a.displayName}
                  </option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item
            name="password"
            label={t("pages.login.fields.password", "Password")}
            rules={[{ required: false }]}
            style={{ marginBottom: "12px" }}
          >
            <Input
              type={passwordShown ? "text" : "password"}
              placeholder="●●●●●●●●"
              size="small"
              prefix={
                <Button
                  key={"passwordRefresh"}
                  type="ghost"
                  block
                  icon={<Icons.EyeFilled />}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                  onClick={() => togglePassword()}
                >
                  {" "}
                </Button>
              }
            />
          </Form.Item>
          <Typography.Text>
            {t("users.filter.passwordDescription")}
          </Typography.Text>
        </Form>
      </Edit>
    </Drawer>
  );
};
