import { useTranslate, useApiUrl, useList } from "@pankod/refine-core";

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  Create,
  Button,
  Icons,
  Col,
  Row,
} from "@pankod/refine-antd";

import { IAgent } from "interfaces";
import { useEffect, useState } from "react";
import { timezones } from "utils";

const { Text } = Typography;

type CreateUserProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const CreateUser: React.FC<CreateUserProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(formProps?.initialValues?.role === "ADMIN" ? true : false);
  const [form] = Form.useForm();

  const { data: agents } = useList<IAgent>({
    resource: "agents",
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handlePassword = () => {
    form.setFieldsValue({
      password: Math.random().toString(36).slice(-8)
    });
  };

  const handleRoleChange = (value: string) => {
    if (value === "ADMIN") {
      form.setFieldsValue({ monitoredAgentIds: [] })
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }

  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () => {
            if (!form.getFieldValue("timezone")) {
              form.setFieldsValue({ timezone: "Europe/Rome" });
            }
            form.submit()
          },
        }}
        pageHeaderProps={{ extra: null }}
        resource="users"
      >
        <Form {...formProps} layout="vertical" form={form}>
          <Form.Item label={t("users.fields.avatar.label")}>
            <Form.Item
              name="avatarUrl"
              valuePropName="fileList"
              getValueFromEvent={(event) => {
                const { fileList } = event;
                if (fileList.length > 0 && fileList[0].response?.url) {
                  fileList[0].url = fileList[0].response.url;
                }
                return [...fileList];
              }}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/icons/upload`}
                headers={{
                  "x-token": `${localStorage.getItem("token")}`,
                }}
                listType="picture"
                maxCount={1}
                accept=".png"
              >
                <Space direction="vertical" size={2}>
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "256px",
                    }}
                    src={
                      (formProps?.initialValues?.avatarUrl &&
                        Array.isArray(formProps?.initialValues?.avatarUrl) &&
                        formProps?.initialValues?.avatarUrl[0]?.url) ||
                      "/images/upload-logo.png"
                    }
                    alt="Store Location"
                  />
                  <Text
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginTop: "8px",
                    }}
                  >
                    {t("users.fields.avatar.description")}
                  </Text>
                  {/*                   <Text style={{ fontSize: "12px" }}>
                    {t("products.fields.images.validation")}
                  </Text> */}
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("users.fields.firstName")}
            name="displayName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.language.label")}
            name="lang"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: t("users.fields.language.en"),
                  value: t("users.fields.language.en"),
                },
                {
                  label: t("users.fields.language.it"),
                  value: t("users.fields.language.it"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("users.fields.timezone")}
            name="timezone"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={formProps?.initialValues?.timezone || "Europe/Rome"}
            >
              {timezones.map((a: string, idx: number) => (
                <option key={idx} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("users.fields.role.label")}
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(value: string) => handleRoleChange(value)}
              options={[
                {
                  label: t("users.fields.role.admin"),
                  value: "ADMIN",
                },
                {
                  label: t("users.fields.role.operator"),
                  value: "OPERATOR",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: t(
                  "pages.login.errors.validEmail",
                  "Invalid email address"
                ),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("pages.login.fields.email", "Email")}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("users.fields.gsm", "Phone")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.isActive.label")}
            name="isActive"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={t("users.fields.agent")}
            name="monitoredAgentIds"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder={t("users.fields.placeholderAgent")}
              disabled={isAdmin}
              defaultValue={formProps?.initialValues?.monitoredAgentIds || []}
            >
              {agents?.data?.map((a: IAgent) => (
                <option key={a.id} value={a.id}>
                  {a.displayName}
                </option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={8}>
            <Col xs={14} lg={12}>
              <Form.Item
                name="password"
                label={t("pages.login.fields.password", "Password")}
                rules={[{ required: true }, { min: 8 }]}
                style={{ marginBottom: "12px", width: "100%" }}
              >
                <Input
                  placeholder="●●●●●●●●"
                  size="middle"
                  type={passwordShown ? "text" : "password"}
                  prefix={
                    <Button
                      key={"passwordRefresh"}
                      type="ghost"
                      block
                      icon={<Icons.EyeFilled />}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        border: "none",
                      }}
                      onClick={() => togglePassword()}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={8} lg={12}>
              <Form.Item
                style={{ marginTop: "35px", marginLeft: "20px", height: "100%" }}
              >
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={handlePassword}
                >
                  {t("users.fields.generatePassword")}
                </Button>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Create>
    </Drawer>
  );
};
